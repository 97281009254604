import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

if (!Bugsnag.isStarted) {
  Bugsnag.start({
    apiKey: "0c841f2a34c05d1c604e3fbd27ffe423",
    plugins: [new BugsnagPluginReact()],
    appType: typeof window === "undefined" ? "server" : "browser",
    enabledReleaseStages: ["production", "staging"],
    releaseStage: process.env.NODE_ENV,
  });
}

export default Bugsnag;

"use client";

import React from "react";
import Bugsnag from "~/bugsnag";

const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

export default function BugsnagBoundary({ children }) {
  if (!ErrorBoundary) return <>{children}</>;
  return <ErrorBoundary>{children}</ErrorBoundary>;
}
